const SELECT_ID = "Select ID";
const FRONT_PICTURE = "Front Picture";
const BACK_PICTURE = "Back Picture";
export const PRIMARY_ID = "Primary";
export const SECONDARY_ID = "Secondary";
const SUBMIT = "Submit";

// Matches the URL
export const PATHNAME_TO_STEP = {
  select_id: SELECT_ID,
  front: FRONT_PICTURE,
  back: BACK_PICTURE,
  submit: SUBMIT,
} as const;

export const STEPS = [
  { name: SELECT_ID },
  { name: FRONT_PICTURE },
  { name: BACK_PICTURE },
  { name: SUBMIT },
] as const;
